<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                :disabled="loading"
                small
                icon
                @click="close"
                color="primary"
                class="mr-2"
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Assign Engineer</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-combobox
                            v-model="user"
                            hide-details
                            label="Users"
                            prepend-icon="mdi-account"
                            :items="users"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="save"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'AssingEngineer',
    props: {
        costId: { type: String, required: true, default: () => '' },
        quoteId: { type: String, required: true, default: () => '' },
        users: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        user: undefined,
        rules: {
            required: v => !!v || 'The param is required',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                let cost = await API.setEngineer(this.quoteId, this.costId, {
                    engineer: this.user.name == 'On Hold' ? '' : this.user.id,
                })

                this.$emit('removeEngineer', cost)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
